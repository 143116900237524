import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As I took my seat on United flight 8826 I thought about how I am shattered, broken into pieces scattered across the globe: my friends, my homes , my culture many of us feel this but I didn’t realize what I was missing until I saw it first hand. The grass is greener on the other side but in this case the grass on my side was half-dead fried by the Malawian sun.`}</p>
    <p>{`I have never lived in the same place for more than four years; fact. I have never been to the same school for more than two years; fact. I have left dozens of friends behind over only 10 years; fact. It’s a depressing reality that I have lived for most of my life. I wasn’t aware of it before Vienna when I stayed somewhere for three years. I had friends who I didn’t make and then immediately leave I lived in the United States a place I felt at home, a place where I was pampered by a chorus of voices speaking in a language that I spoke, a grocery store with hundreds of different varieties of cereal, and by a school system that was the best of the best. I realized what I gave up not having a home.`}</p>
    <p>{`More importantly though I thought about how I am whole, like a snowstorm I move all over leaving parts of me behind wherever I go. Most don’t realize what they’re missing.The grass is greener on the other side.`}</p>
    <p>{`I have experienced more cultures, made more friends and have seen more countries than most people will in a lifetime. I have made friends from Nepal, Japan, Germany, Malawi, South Africa, Egypt ,Japan, South Korea, ect. I have eaten the best food in the world which is (obviously), Japanese Food. I have taken trains to powder rich mountains and watched the sunset behind them from Ski Side Onsens. I have spent years in highly regarded schools across the world. I’ve been on safari seeing wild lions and hippos. I have experienced so much. I am the snowstorm one flake isn’t anything special but when there are enough of them and they are spread out enough you feel like you’re floating on air.`}</p>
    <p>{`Am I the snowstorm or am I shattered,the answer lies in the buried deep inside of me. I’ve felt both ways at times.However, wherever I am I live by one rule which is to make the best with what I’ve got and to try and be happy. Which at the end of the day is all we can do.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      